.form_wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 60px 50px;
}

.i_row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.i_row>div {
    width: 100%;
    /* background-color: darkblue; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 10px;

}

.i_row>div label,
.d_row label,
.m_d_row label {
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 500;

}

.i_row>div input,
.m_d_row input {
    width: 100%;
    padding: 0.625rem 0.75rem;
    color: #212529;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: 1px solid #ced4da;
    background-clip: padding-box;
    line-height: 1.5;
    font-size: 16px;
    background-color: #EFEFEF;
}

.m_d_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 20px;

}

.m_d_row>div {
    width: 100%;
    padding: 0 10px;
}

.dd_conte {
    display: flex;
    align-items: center;
    justify-content: space-evenly !important;
}

.dd_conte label {
    margin-left: 5px;
}


.d_row {
    padding: 0 10px;
    padding-bottom: 20px;

}

.radio_wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #EFEFEF;
    padding: 0.4rem 0.75rem;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: 1px solid #ced4da;

    background-clip: padding-box;
}


.form_button {
    font-family: 'Poppins', sans-serif;
    background-color: #475cb7;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    padding: 0.688rem 0.75rem;
    /* padding: 0.5rem 0.75rem; */

    border-radius: 7px;
    cursor: pointer;
    transition: color ease 0.3s;
}

.form_button:hover {
    transition: background ease 0.3s;
    background-color: #5468c0;
}

.msg_div {
    background-color: #bcd185;
    margin: auto;
    width: 95%;
    max-width: 910px;
    border-radius: 6px;
    padding: 12px 20px;
    font-size: 18px;
    font-weight: 300;
}

.red_star{
    color: red;
    font-weight: 300;
    display: inline-block;
    transform: translateY(-2px);
}

@media screen and (max-width: 1270px) {
    .form_wrapper {
        padding: 60px 30px;
    }
}

@media screen and (max-width: 768px) {

    .form_wrapper {
        padding: 60px 20px;
    }

    .i_row {
        display: block;
        padding-bottom: 0px;
    }

    .i_row>div {
        display: block;
        padding: 0px;
        margin-bottom: 15px;
    }

    .i_row>div label,
    .d_row label,
    .m_d_row label {
        font-size: 16px;
    }

    .i_row>div input,
    .m_d_row input {
        font-size: 16px;
    }

    .m_d_row {
        display: block;
        padding-bottom: 0px;

    }

    .m_d_row>div {
        padding: 0px;
        margin-bottom: 15px;

    }

    .dd_conte {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .dd_conte label {
        margin-left: 5px;
    }


    .d_row {
        padding: 0px;
        padding-bottom: 15px;

    }


    .form_button {
        font-family: 'Poppins', sans-serif;
        background-color: #475cb7;
        border: none;
        color: white;
        font-size: 16px;
        font-weight: 500;
        padding: 0.688rem 0.75rem;
        /* padding: 0.5rem 0.75rem; */
        border-radius: 7px;
        cursor: pointer;
        transition: color ease 0.3s;
        margin: 0 auto;
        display: block;
    }

    .form_button:hover {
        transition: background ease 0.3s;
        background-color: #5468c0;
    }


}