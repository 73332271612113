.carousal_contaier {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 50px;
    padding-bottom: 50px;
}

.team_member_wrapper {
    display: flex;
    /* margin: 0 70px; */
    /* margin-top: 40px; */
    /* width: 280px; */
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
}

.team_member_wrapper h3 {
    text-align: center;
    width: 100%;
    font-size: 25px;
    letter-spacing: 0px;
    color: #1363a7;
    margin: 0;
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.team_member_wrapper p {
    text-align: left;
    font-size: 18px;
    line-height: 1.6;
    letter-spacing: 0px;
    color: #000000;
    margin: 0px;
    margin-top: 15px;
}

.team_member_wrapper img {
    width: 100%;
    aspect-ratio: 1.75;
    border-radius: 10px;
}

.services_p {
    max-width: 1440px;
    padding: 20px 50px;
    margin: 0 auto;
    font-size: 19px;
    line-height: 32px;
}

.services_p span {
    color: #1363a7;
}

.swiper-button-prev,
.swiper-button-next {
    /* color: white !important; */
    /* width: 40px !important; */
}


@media screen and (max-width: 1270px) {

    .services_p {
        padding: 20px 30px;
    }

    .carousal_contaier {
        padding: 0 30px;
    }
}

@media screen and (max-width: 768px) {
    .services_p {
        padding: 10px 20px;
        font-size: 16px;
        line-height: 26px;
    }
    .carousal_contaier {
        padding: 0 10px;
    }
    
    .team_member_wrapper p {
        font-size: 17px;
        padding-bottom: 30px;
    }
    
}

    @media screen and (max-width: 310px) {
        .team_member_wrapper img {
            width: 100px;
            border-radius: 50px;
            height: 100px;

        }

    }