.cover_container {
    position: relative;
    margin-top: -92.45px;
    height: auto;
    height: 100vh;
    overflow: hidden;
}

.cover_image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cover_container img {
    width: 100%;
    filter: brightness(80%);
    -webkit-filter: brightness(80%);
    -moz-linear-filter: brightness(80%);
}

.cover_content {
    position: absolute;
    top: 50%;
    width: 100%;
    margin: 0 auto;
    transform: translateY(-50%);
}

.cover_wrapper {
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 50px;
}

.cover_wrapper h1 {
    font-weight: 700;
    color: #fff;
    font-size: 50px;
    text-transform: uppercase;
    line-height: 1.2;
    max-width: 47%;
}

.cover_wrapper p {
    font-size: 18px;
    color: #fff;
    max-width: 47%;
    margin-top: 25px;
}

.cover_wrapper button {
    font-family: 'Poppins', sans-serif;
    background-color: #475cb7;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    padding: 6px 20px;
    border-radius: 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: color ease 0.3s;
    margin-top: 25px;

}

.cover_wrapper button:hover {
    transition: background ease 0.3s;
    background-color: #5468c0;

}

@media screen and (max-width: 1270px) {
    .cover_wrapper {
        padding: 0 30px;
    }

}

@media screen and (max-width: 1042px) {

    .cover_container {
        height: auto;
        min-height: 80vh;
    }
}

@media screen and (max-width: 970px) {
    .cover_wrapper h1 {
        font-weight: 700;
        color: #fff;
        font-size: 40px;
        text-transform: uppercase;
        line-height: 1.2;
        max-width: 60%;
    }

    .cover_wrapper p {
        font-size: 16px;
        color: #fff;
        max-width: 60%;
        margin-top: 22px;
    }

}

@media screen and (max-width: 841px) {
    .cover_container {
   
        min-height: 70vh;
    }
}

@media screen and (max-width: 768px) {
    .cover_container {
        position: relative;
        margin-top: 0px;
        height: auto;
        width: 100%;
        overflow: hidden;
    }

    .cover_image {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cover_image img {
        width: auto;
        height: calc(100vh - 60px);
    }


    .cover_content {
        position: absolute;
        top: 50%;
        width: 100%;
        margin: 0 auto;
        transform: translateY(-50%);
    }

    .cover_wrapper {
        margin: 0 auto;
        max-width: none;
        padding: 0 20px;
    }

    .cover_wrapper h1 {
        font-weight: 700;
        color: #fff;
        font-size: 32px;
        text-transform: uppercase;
        line-height: 1.2;
        max-width: 100%;
    }

    .cover_wrapper p {
        font-size: 16px;
        color: #fff;
        max-width: none;
        margin-top: 15px;
        line-height: 26px;

    }

    .cover_wrapper button {
        font-family: 'Poppins', sans-serif;
        background-color: #475cb7;
        border: none;
        color: white;
        font-size: 16px;
        font-weight: 500;
        padding: 6px 20px;
        border-radius: 20px;
        text-transform: uppercase;
        cursor: pointer;
        transition: color ease 0.3s;
        margin-top: 15px;

    }

    .cover_wrapper button:hover {
        transition: background ease 0.3s;
        background-color: #5468c0;

    }
}