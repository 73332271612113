.Moday_cont {
    font-family: 'poppins', sans-serif;
    text-align: center;
}

.mymodal {
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translate(-50%, 0%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 8px;
    outline: none;
    padding: 20px;
    text-align: center;
    width: 460px;

}

.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
    z-index: 3222;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.subscribe_input {
    width: 100%;
    padding: 0.625rem 0.75rem;
    color: #212529;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: 1px solid #ced4da;
    background-clip: padding-box;
    line-height: 1.5;
    font-size: 16px;
    background-color: #EFEFEF;
    width: 100%;
    margin-bottom: 20px;
    outline: none;
}
.modal_heading{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}
.unsub{
    margin-top: 7px;
    display: block;
}
.unsub a{
    font-size: 15px;
    color: #475cb7;
    /* text-decoration: none; */
}

@media screen and (max-width: 836px) {
    .mymodal {
        top: 15%;
        left: 0%;
        transform: translate(0%);
        width: 100%;
    }
    
}