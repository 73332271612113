.mobile_navbar_container {
    display: none;
}

.navbar_container_wrapper {
    width: 100%;
    top: 0px;
    position: sticky;
    z-index: 200;
    font-family: 'Poppins', sans-serif;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    transition: all ease-out 0.4s;

}

.navbar_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    padding: 15px 50px;
    color: white;
    transition: all ease-out 0.5s;
}

.navbar_container_up .navbar_container {
    padding: 10px 50px;
}

.logo_container {
    width: 200px;
}

/* .navbar_container_up .logo_container{
    width: 130px;
    
} */

.hide_navbar {
    top: -200px;
    transition: all ease-out 0.5s;
}

.logo_container img {
    width: 100%;
}

.menu_container {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menu_container span {
    margin-left: 30px;
}

.menu_container span::after {
    display: block;
    content: '';
    width: calc(100% + 12px);
    border-bottom: solid 3px #475cb7;
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
    margin-left: -6px;
}

.menu_container span a {
    color: inherit;
    text-decoration: none;
    transition: color ease 0.3s;
}

.menu_container span a:hover {
    color: #475cb7;
    transition: color ease 0.3s;
}

.btn_container {
    margin-left: auto;
}

.btn_container button {
    font-family: 'Poppins', sans-serif;
    background-color: #475cb7;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    padding: 6px 20px;
    border-radius: 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: color ease 0.3s;
}

.btn_container button:hover {
    transition: background ease 0.3s;
    background-color: #5468c0;
}

.active_menu {
    color: #475cb7;
}

.menu_container span:hover:after {
    transform: scaleX(1);
}

.active_menu::after {
    transform: scaleX(1) !important;
}

.active_menu a {
    transition: background 300ms, color 300ms, border-color 300ms;
    -webkit-transition: background 300ms, color 300ms, border-color 300ms;
    /* color: #5100a2 !important; */
}


@media screen and (max-width: 1270px) {
    .navbar_container {

        padding: 15px 30px;
    }

    .menu_container {
        margin-left: 10px;
    }

    .menu_container span {
        margin-left: 20px;
    }
}

@media screen and (max-width: 1045px) {
    .logo_container {
        width: 170px;
    }

    .menu_container {
        font-size: 15px;
        margin-left: 10px;
    }

    .menu_container span {
        margin-left: 15px;
    }

    .btn_container button {
        font-size: 15px;
    }
}

@media screen and (max-width: 952px) {

    .logo_container {
        width: 150px;
    }

    .menu_container {
        font-size: 13px;
        margin-left: 10px;
    }

    .menu_container span {
        margin-left: 10px;
    }

    .btn_container button {
        font-size: 13px;
        padding: 5px 15px;
    }
}

@media screen and (max-width: 836px) {
    .navbar_container {
        display: none;
    }

    span.telegram_ {
        margin-right: 10px;
        margin-left: 0px;

    }

    span.telegram_ img {
        height: 35px;
        cursor: pointer;
        width: auto;
        transition: all 0.3s ease-in-out;
        transform: translateY(-3px);
    }

    .mobile_navbar_container {
        display: block;
        z-index: 333;
        width: 100%;
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        font-size: 20px;
        padding: 0px 20px;
        padding-top: 7px;
        background-color: #ffffff;
        width: 100%;
    }

    .m_logo {
        width: 100%;
        max-width: 120px;
    }

    .m_logo img {
        width: 100%;
    }

    .toggle_btn {
        display: inline-block;
        font-size: 26px;
        color: #475cb7;
    }

    .menu_section_mobile {
        position: absolute;
        top: 40px;
        left: 0;
        flex-basis: 100%;
        padding: 10px 20px;
        margin-top: 0px;
        background-color: white;
        width: 100%;
        z-index: 111;
        padding-bottom: 30px;
    }

    .menu_section_mobile span {
        display: block;
        font-size: 16px;
        padding: 10px 0;
        text-align: left;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        font-weight: 500;
        color: #475cb7;
        border-bottom: 1px solid black;

    }

    .m_icons_container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }


    .m_icons_container .btn_container {
        transform: translate(-8px, -6px);
    }
    .m_icons_container button{
        font-size: 13px;
        padding: 4px 12px;
    }

    .menu_section_mobile span a {
        color: inherit;
        text-decoration: none;
    }

    .menu_section_mobile span:hover {
        color: #475cb7;
    }
}