.partner_images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    padding: 25px 50px;
}

.partner_image_wrapper {
    width: 100%;
}

.fi_images {
    width: 150px;
    cursor: pointer;

}

.m_image {
    width: 240px;
    cursor: pointer;

}

.sq_images {
    width: 90px;
    cursor: pointer;

}

.ls_images {
    width: 110px;
    cursor: pointer;

}

.partner_heading {
    background-color: #475CB7;
    color: white;
    text-align: center;
    padding: 20px;
}

.partner_heading h1 {
    font-size: 35px;
    font-weight: 500;
}

.red_line {
    padding: 7px;
    background-color: #A1332B;
    border-bottom: 1px solid white;
}

.partner_content {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 1.6;
    padding: 20px 50px;
}

.past_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 50px;
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
}

.sub_past_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.333%;
}

.sub_past_wrapper div {
    text-align: center;
    margin-left: 20px;
    width: 100%;
}

.sub_past_wrapper img {
    width: 95px;
}

.sub_past_wrapper p {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
}

@media screen and (max-width: 1270px) {
    .partner_images {
        padding: 25px 30px;
    }

    .partner_content {
        padding: 20px 30px;
    }

    .past_wrapper {
        padding: 50px 30px;
    }
}

@media screen and (max-width: 970px) {
    .partner_images {
        padding: 20px 30px;
    }

    .fi_images {
        width: 140px;
    }

    .m_image {
        width: 210px;
    }

    .sq_images {
        width: 80px;
    }

    .ls_images {
        width: 100px;
    }

    .sub_past_wrapper img {
        width: 70px;
    }

    .sub_past_wrapper p {
        font-size: 16px;
    }

    .sub_past_wrapper div {
        margin-left: 5px;
    }
}

@media screen and (max-width: 769px) {
    .partner_images {
        display: block;
        text-align: center;
        padding: 25px 20px;
    }

    .partner_image_wrapper {
        width: 100%;
    }

    .fi_images {
        width: 130px;
        padding-top: 0px;

    }

    .m_image {
        width: 200px;
        padding-top: 20px;

    }

    .sq_images {
        width: 80px;
        padding-top: 20px;

    }

    .ls_images {
        width: 110px;
        padding-top: 20px;

    }

    .partner_heading {
        padding: 15px;
    }

    .partner_heading h1 {
        font-size: 30px;
    }


    .partner_content {
        font-size: 16px;
        padding: 20px 20px;
    }

    .past_wrapper {
        display: block;
        padding: 50px 20px;
        width: 100%;
        text-align: center;
        padding-bottom: 30px;
    }

    .sub_past_wrapper {
        display: block;
        text-align: center;
        width: 100%;
    }

    .sub_past_wrapper div {
        margin-left: 0px;
        padding-bottom: 20px;
    }


    .sub_past_wrapper p {
        font-size: 18px;
    }
}