.footer_container_bg {
    background-color: #2E2E2E;
    width: 100%;
}

.footer_container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: white;
    padding: 50px 50px;
}

.footer_container div {
    /* width: 33%; */
}

.footer_logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_logo img {
    width: 250px;
}

.footer_middle span {
    display: block;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-transform: capitalize;
}

.footer_middle span a {
    color: inherit;
    text-decoration: none;
    transition: color ease 0.3s;
}

.footer_right {
    align-self: center;
}

.footer_right span {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.5px;

}

.footer_middle span a:hover {
    color: #475cb7;
    transition: color ease 0.3s;
}

.icons_container {
    margin-bottom: 15px;
}

.icons_container span {
    margin-right: 12px;
    font-size: 20px;
}

.icons_container span:hover {
    cursor: pointer;
    color: #475cb7;
    transition: color ease 0.3s;
}

@media screen and (max-width: 1270px) {

    .footer_container {
        padding: 50px 30px;
    }
}


@media screen and (max-width: 970px) {
    .footer_logo img {
        width: 210px;
    }

    .footer_right span {
        font-size: 18px;
        line-height: 28px;
    }

    .icons_container span {
        font-size: 18px;
    }

    .footer_middle span {
        font-size: 18px;
        line-height: 28px;
    }
}

@media screen and (max-width: 768px) {

    .footer_container {
        padding: 50px 20px;
        flex-wrap: wrap;
    }

    .footer_logo {
        flex-basis: 100%;
        margin-bottom: 20px;
    }

    .footer_middle{
        flex-basis: 50%;
    }

    .footer_middle span {
        font-size: 16px;
        line-height: 32px;
    }

    .footer_right {
        align-self: center;
        flex-basis: 50%;
        text-align: right;
    }

    .footer_right span {
        font-size: 16px;
    }

    .icons_container {
        margin-bottom: 15px;
    }

    .icons_container span {
        margin-right: 12px;
        font-size: 20px;
    }

}