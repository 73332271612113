.about_bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    padding: 20px 50px;
}

.theme_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    padding: 10px 50px;
    margin: 0 auto;
}


.theme_heading h1 {
    font-size: 34px;
    font-weight: 500;
    color: #1363a7;
}

.img_div {
    transform: translateY(10px);
    /* background-color: darkblue; */
    overflow: hidden;
}

.theme_heading img {
    width: 100%;
    margin-left: 20px;
}


.paint_image {
    /* mawidth: 180px; */
    /* margin-right: 50px; */
}

.paint_image img {
    width: 150px;
    margin-right: 50px;

}

.about_bottom_text p {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 30px;
}

.about_bottom_text p span {
    color: #1363a7;
}

@media screen and (max-width: 1030px) {
    .paint_image img {
        width: 130px;
        margin-right: 40px;

    }

    .about_bottom_text p {
        font-size: 16px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1270px) {
    .theme_heading {
        padding: 10px 30px;
    }

    .about_bottom {
        padding: 20px 30px;
    }
}
@media screen and (max-width: 769px) {
    .about_bottom {
        display: block;
        padding: 20px 20px;
    }
    
    .theme_heading {
        padding: 10px 20px;
    }
    
    
    .theme_heading h1 {
        font-size: 28px;
    }
    
    .theme_heading img {
        margin-left:10px;
    }
    
    
    .paint_image {
        text-align: center;
    }
    
    .paint_image img {
        width: 110px;
        margin-right: 0px;
    
    }
    
    .about_bottom_text p {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
    }
}
