.career_p {
    max-width: 1440px;
    padding: 5px 50px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 32px;
    color: #1363a7;
    font-weight: bold;
}

@media screen and (max-width: 1270px) {
    .career_p {
        padding: 5px 30px;

    }
}


@media screen and (max-width: 768px) {
    .career_p {
        padding: 5px 20px;

    }
}